import styles from "./GameDevelopers.module.scss";
import cuteMonster from "src/modules/gameDevelopers/assets/img/png/cuteMonster.png";
import cl from "classnames";
import shadow from "src/modules/gameDevelopers/assets/img/svg/shadowCards.svg";
import { useRef, useState, useEffect } from "react";

export const GameDevelopers = () => {
	const containerIcons = useRef<HTMLDivElement>(null);
	const [randValues, setRandValues] = useState<number[] | null>(null);

	function getRandomElements(arr, count) {
		let shuffled = arr.slice(0),
			i = arr.length,
			min = i - count,
			temp,
			index;
		while (i-- > min) {
			index = Math.floor((i + 1) * Math.random());
			temp = shuffled[index];
			shuffled[index] = shuffled[i];
			shuffled[i] = temp;
		}
		return shuffled.slice(min);
	}

	useEffect(() => {
		let arr: number[] = [0, 1, 2, 3];
		setRandValues(getRandomElements(arr, 1));

		setTimeout(() => {
			let arr: number[] = [0, 1, 2, 3];
			setRandValues(getRandomElements(arr, 1));
		}, 1000);

		setInterval(() => {
			let arr: number[] = [0, 1, 2, 3];
			setRandValues(getRandomElements(arr, 1));
		}, 3000);
	}, []);

	useEffect(() => {
		if (containerIcons.current && randValues) {
			randValues.forEach((value) => {
				//@ts-ignore
				containerIcons.current.childNodes[value].style.transform = "scale(0.8)";
				setTimeout(() => {
					try {
						//@ts-ignore
						containerIcons.current.childNodes[value].style.transform =
							"scale(1)";
					} catch (e) {}
				}, 3000);
				// setTimeout(() => {
				// 	try {
				// 		//@ts-ignore
				// 		containerIcons.current.childNodes[value].style.transform =
				// 			"scale(1.1)";
				// 	} catch (e) {}
				// }, 6000);
			});
		}
	}, [randValues]);

	return (
		<div className={styles.wrapper} id={"gameDevelopers"}>
			<img src={shadow} alt="" className={styles.shadow} />
			<div className={styles.content}>
				{/* <div className={styles.title}>HOW WE HELP GAME DEVELOPERS</div> */}
				<div className={styles.mainContent}>
					<div className={styles.cards} ref={containerIcons}>
						<div className={cl(styles.card, styles.card1)}>
							<div className={styles.cardTitle}>CO-MARKETING</div>
							<div className={styles.cardDescription}>
								Guild membership includes marketing support, such as social
								media promotion, content creation, and influencer partnerships.
								Cross-marketing with other guild games can also expand reach and
								attract diverse audiences​.
							</div>
						</div>
						<div className={cl(styles.card, styles.card2)}>
							<div className={styles.cardTitle}>Development Support</div>
							<div className={styles.cardDescription}>
								We offer development assistance, including tutorials, guides,
								and resources for building on the SEI blockchain. This support
								helps streamline development processes, reduce costs, and
								enhance game quality.
							</div>
						</div>
						<div className={cl(styles.card, styles.card3)}>
							<div className={styles.cardTitle}>
								Joint Events and Tournaments
							</div>
							<div className={styles.cardDescription}>
								We will be hosting numerous events and tournaments, providing
								games with additional exposure and engagement opportunities.
								These events can attract new players and retain existing ones by
								creating a competitive and social atmosphere
							</div>
						</div>
						<div className={cl(styles.card, styles.card4)}>
							<div className={styles.cardTitle}>
								 Financial Incentives and Grants
							</div>
							<div className={styles.cardDescription}>
								Guild members can access various financial incentives, including
								grants, play-to-earn scholarships, and staking rewards. These
								incentives can provide additional funding and resources for game
								development and marketing​
							</div>
						</div>
					</div>
					<img src={cuteMonster} alt="" />
				</div>
			</div>
		</div>
	);
};
